import React from 'react'

import config from '../../config/website'
import Header from '../components/Header'

const NotFoundPage = () => (
  <>
    <Header siteTitle={config.siteTitle} small />
    <div className="cf pv3 ph3 ph1-ns vh-100 mw9 center abs-c">
      <header className="fl w-100">
        <h2 className="lh-title f2 tc fw3 mv0 tracked white">Oups !</h2>
      </header>
      <section className="fl w-100">
        <p className="f5 fw3 lh-copy tracked white measure center tc">
          La page demandée est introuvable ou n'existe pas.
        </p>
      </section>
    </div>
  </>
)

export default NotFoundPage
