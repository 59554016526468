import {Link, withPrefix} from 'gatsby'
import {string} from 'prop-types'
import React from 'react'

const siteLogo = withPrefix('/logos/logo-vv.svg');
const siteImage = withPrefix('/home-image.jpg');

const Header = ({ siteTitle }) => (
  <header className="dt w-100">
    <div className="bg-vvood white vh-100 dtc tc v-mid">
      <div className="bg-fs o-70 absolute absolute--fill"
        style={{ backgroundImage: `url('${siteImage}')`}} />
      <nav id="shop" className="tc absolute bottom-0 pv4 w-100">
        <Link to={"/"} className="vvood fw9 tracked-tight tc v-mid db no-underline">
          <img src={siteLogo} alt={siteTitle} width="100" />
          <div className="f7 white normal ttu tracked pt4">Tourneurs sur bois</div>
        </Link>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: string.isRequired
}

export default Header